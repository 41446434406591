import { combineReducers, Reducer } from 'redux'
import { StateType } from 'typesafe-actions'
import type { AdminModuleSlice } from './Admin/store'
import { advisoryModuleReducer, advisoryModuleSagas } from './Advisory'
import { aiRevenueReducer, aiRevenueSagas } from './AIRevenue'
import {
  compliancereducer,
  IComplianceModuleState
} from './Compliance/store/reducers'
import { sagas as complianceSagas } from './Compliance/store/sagas'
import {
  IThirdPartyManagerModuleState,
  thirdPartyManagerFirmsReducer
} from './ExternalManagers/store/reducers'
import { sagas as thirdPartyManagerSagas } from './ExternalManagers/store/sagas'
import { feesReducer, IFeesState } from './Fees/store/reducer'
import { sagas as feeModuleSagas } from './Fees/store/sagas'
import { firmReducer, IFirmState } from './Firm/store/reducer'
import { sagas as firmModuleSagas } from './Firm/store/sagas'
import { householdsReducer, householdSagas } from './Households/store'
import { manageaccountReducer } from './ManageAccount/store/ManageAccountReducer'
import { performanceReducer } from './Operations/modules/Performance/store/reducer'
import { sagas as performanceSagas } from './Operations/modules/Performance/store/sagas'
import { IPaymentModuleState, paymentreducer } from './Payments/store/reducers'
import { sagas as paymentsSagas } from './Payments/store/sagas'
import { IProductModuleState, productReducer } from './Products/store/reducers'
import { sagas as productSagas } from './Products/store/sagas'
import { IReportModuleState, reportreducer } from './Reports/store/reducer'
import { sagas as reportSagas } from './Reports/store/sagas'

import { dueDiligencesReducer } from './Security/features/DueDiligence/store/reducer'
import { dueDiligenceSagas } from './Security/features/DueDiligence/store/sagas'
import {
  overridesReducer,
  overridesSagas
} from './Security/features/Overrides/store'
import type { TeamsModuleSlice } from './Teams/store'
import {
  ITransferModuleState,
  transferReducer
} from './Transfers/store/reducers'
import { sagas as transfersSagas } from './Transfers/store/sagas'

import { ITrustModuleState, trustreducer } from './Trusts/store/reducers'
import { sagas as trustSagas } from './Trusts/store/sagas'

export const modulesSagas = [
  ...advisoryModuleSagas,
  ...paymentsSagas,
  ...transfersSagas,
  ...feeModuleSagas,
  ...trustSagas,
  ...householdSagas,
  ...reportSagas,
  ...complianceSagas,
  ...productSagas,
  ...overridesSagas,
  ...dueDiligenceSagas,
  ...performanceSagas,
  ...thirdPartyManagerSagas,
  ...firmModuleSagas,
  ...aiRevenueSagas
]

interface IModulesState {
  advisory: ReturnType<typeof advisoryModuleReducer>
  fees: IFeesState
  payments: IPaymentModuleState
  transfers: ITransferModuleState
  manageaccount: StateType<typeof manageaccountReducer>
  trust: ITrustModuleState
  households: StateType<typeof householdsReducer>
  products: IProductModuleState
  reports: IReportModuleState
  compliance: IComplianceModuleState
  overrides: StateType<typeof overridesReducer>
  duediligence: StateType<typeof dueDiligencesReducer>
  performance: StateType<typeof performanceReducer>
  thirdPartyManager: IThirdPartyManagerModuleState
  firm: IFirmState
  airevenue: ReturnType<typeof aiRevenueReducer>
}

export type DynamicModulesState = AdminModuleSlice & TeamsModuleSlice
export type StaticModulesState = IModulesState

export const modulesReducer: Reducer<IModulesState> = combineReducers({
  advisory: advisoryModuleReducer,
  fees: feesReducer,
  payments: paymentreducer,
  transfers: transferReducer,
  manageaccount: manageaccountReducer,
  trust: trustreducer,
  households: householdsReducer,
  reports: reportreducer,
  compliance: compliancereducer,
  products: productReducer,
  overrides: overridesReducer,
  duediligence: dueDiligencesReducer,
  performance: performanceReducer,
  thirdPartyManager: thirdPartyManagerFirmsReducer,
  firm: firmReducer,
  airevenue: aiRevenueReducer
})
