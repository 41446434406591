import { OdataFilterOperatorEnum, OdataPropertyFilterGroup } from 'api/odata'
import { search } from 'api/search'
import { push } from 'connected-react-router'
import { cloneDeep } from 'lodash'
import { base64toBlob } from 'modules/Reports/features/Dashboard/Utilities'
import {
  call,
  delay,
  put,
  putResolve,
  race,
  take,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { fetchPaymentByIDActions } from '../../Dashboard/modules/Dashboard/store/actions'
import {
  getAccountHolders,
  getPastStandingInstructions
} from '../api/EFTJNLServices'
import {
  IAbaResponse,
  IWirePaymentResponse,
  PartyPhoneResponse,
  IStandingInstructionsResponse,
  IWireSaveDocRequest,
  // IWireUploadDocRequest,
  IReprocessResponse
} from '../api/types'
import {
  createPaymentService,
  evaluateTransactionRiskSerivce,
  submitRiskAcknowledgementService,
  fetchAbaDetails,
  fetchCurrencyCodeListService,
  fetchMethodofInstructionsService,
  fetchPartyPhoneNumbersService,
  fetchMMDeviceInfo,
  fetchWirePayments,
  getStandingInstructionsList,
  getPastPayments,
  getRiskEvalResultSerivce,
  mmValidateService,
  submitPaymentReview,
  getTranApproveDetail,
  fetchInsufFundSolutionListService,
  fetchRequiredDocTypes,
  uploadWireDocument,
  saveWireDocument,
  fetchWireDocToView,
  handleWireDocDelete,
  fetchholidayList,
  handleWireDocDeleteFromStorage,
  uploadWireDocumentMetadata,
  fetchAccountBalance,
  reprocessTransaction,
  SubmitOPSAssigment,
  declineTransaction,
  closeTransaction,
  getAccountProperties,
  fetchForeignCurrencyBalance,
  getWireType,
  updateFedRefNo,
  deleteTransaction,
  getMMLimitsService,
  fetchMMConfig,
  fetchAnticipatedAccountBalance,
  getPeriodicPaymentHistory,
  getPeriodicPlanHistory,
  getStandingInstructionById
} from '../api/WireService'
import { RecipientType, TransactionType } from '../EFT/Constants'
import { createWireRequest, updatedRequest } from '../utilities/Utilities'
import {
  FetchAbaDetailActions,
  FetchSwiftCodeDetailActions,
  FetchWirePaymentActions,
  fetchCountriesListActions,
  fetchCurrencyCodeListActions,
  fetchPartyPhoneListActions,
  fetchMethodOfInstructionListActions,
  createPaymentActions,
  fetchPaymentAttensionActions,
  fetchPaymentStandingInstructionsActions,
  mmValidatePaymentActions,
  setFormDataActions,
  RiskAcknowledgementActions,
  mmWireSaveAsDraftActions,
  fetchPastPaymentsActions,
  processMMDeviceInfoActions,
  fetchPaymentRiskEvalResultActions,
  SubmitPaymentReviewActions,
  FetchPaymentApproveDetailsActions,
  fetchInsufficientFundSolListActions,
  fetchWireDocTypeListActions,
  uploadWireDocumentActions,
  viewWireDocumentActions,
  deleteWireDocumentActions,
  fetchHolidaysActions,
  fetchAccountBalanceActions,
  fetchForeignAccountBalanceActions,
  clientSearchActions,
  accountSearchActions,
  reprocessTransactionActions,
  SubmitOPSAssignmentActions,
  declineTransactionActions,
  closeTransactionActions,
  fetchAuthUserListActions,
  mmValidateAccountActions,
  fetchWireTypeActions,
  updateFedRefNoActions,
  deleteTransactionActions,
  mmProcessActions,
  fetchPastStandingDataActions,
  fetchAccountHolderActions,
  getMMLimitActions,
  getMMConfigActions,
  fetchAnticipatedBalanceActions,
  fetchPeriodicHistoryActions,
  fetchStandingInstByIdActions
} from './actions'

import { ICheckPayeeDetails } from './Check/types'
import { IPastStandingInstructionResponse } from './EFT/types'
import {
  IAccountBalanceResponse,
  IAccountHoldersResponse,
  IAccountProperties,
  IAnticipatedBalance,
  IAttensionResponse,
  IForeignCurrencyPosition,
  IGenericResponse,
  IgetWireTypeResponse,
  IMMDeviceInfoResponse,
  IMMSummarySIById,
  IMMValidateResponse,
  IPastPaymentsResponse,
  IPaymentApproveDetailsResponse,
  IPaymentReviewResponse,
  IPeriodicPaymentHistoryResponse,
  IPeriodicPlanHistoryResponse,
  IRiskRuleAck,
  IStandingInstructionLimitResponse,
  IUpdateFedRefResponse,
  IWireInitiationForm,
  IWireRiskAckRequest,
  IWireRiskAckResponse
} from './types'

function* handleFetchAbaDetails(
  action: ReturnType<typeof FetchAbaDetailActions.request>
) {
  try {
    const abaNumber = action.payload.routingNumber as string
    const country = action.payload.country
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response: IAbaResponse | undefined = yield call(() =>
      fetchAbaDetails(abaNumber, country, options)
    )
    if (response?.status?.Code === '000000' && response?.ABANumbers?.length) {
      yield put(FetchAbaDetailActions.success(response.ABANumbers?.[0]))
    } else {
      throw new Error('Failed to fetch ABA Number details')
    }
  } catch (e: any) {
    yield put(FetchAbaDetailActions.failure(e))
  }
}

function* handleFetchSwiftCodeDetails(
  action: ReturnType<typeof FetchSwiftCodeDetailActions.request>
) {
  try {
    const swiftCode = action.payload.swiftCode as string
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response: IAbaResponse | undefined = yield call(() =>
      fetchAbaDetails(swiftCode, undefined, options, true)
    )
    if (response?.status?.Code === '000000' && response?.ABANumbers?.length) {
      yield put(FetchSwiftCodeDetailActions.success(response.ABANumbers?.[0]))
    } else {
      throw new Error('Failed to fetch Swift Code details')
    }
  } catch (e: any) {
    yield put(FetchSwiftCodeDetailActions.failure(e))
  }
}

function* handleFetchWirePayments(
  action: ReturnType<typeof FetchWirePaymentActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IWirePaymentResponse = yield call(() =>
      fetchWirePayments(action.payload.fromDate, action.payload.toDate, options)
    )

    if (data?.response_status === 0) {
      yield put(
        FetchWirePaymentActions.success(
          data?.response_data ? data.response_data : []
        )
      )
    } else {
      yield put(
        FetchWirePaymentActions.failure(
          new Error('Unable to fetch wire payments')
        )
      )
    }
  } catch (e: any) {
    yield put(FetchWirePaymentActions.failure(e))
  }
}

function* handleFetchWireDocTypes(
  action: ReturnType<typeof fetchWireDocTypeListActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchRequiredDocTypes(
        options,
        action?.payload.tranType,
        action?.payload?.tranId
      )
    )
    if (response?.rspStatus?.statusCode === '0') {
      yield put(fetchWireDocTypeListActions.success(response?.rspn || []))
    } else {
      yield put(
        fetchWireDocTypeListActions.failure(
          new Error(response?.rspStatus?.message)
        )
      )
    }
  } catch (e: any) {
    yield put(fetchWireDocTypeListActions.failure(e))
  }
}

function* handleViewDoc(
  action: ReturnType<typeof viewWireDocumentActions.view>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchWireDocToView(
        options,
        action.payload.fileId,
        action.payload.storageDocKey
      )
    )
    if (response) {
      const nbase64Data = response.replace('data:application/pdf;base64,', '')
      const blob = base64toBlob(nbase64Data, 'application/pdf')
      const url = URL.createObjectURL(blob)
      window.open(url)
    }
  } catch (e: any) {
    console.log(e)
  }
}

function* handleDeleteDoc(
  action: ReturnType<typeof deleteWireDocumentActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      handleWireDocDelete(
        options,
        action.payload.tranId,
        action.payload.storageDocKey
      )
    )
    if (response?.statusCode === '0') {
      yield put(deleteWireDocumentActions.success(action.payload.storageDocKey))
      yield* call(() =>
        handleWireDocDeleteFromStorage(
          options,
          action.payload.fileId,
          action.payload.storageDocKey
        )
      )
    } else {
      yield put(deleteWireDocumentActions.failure(new Error(response?.message)))
    }
  } catch (e: any) {
    yield put(deleteWireDocumentActions.failure(e))
  }
}

function* handleWireUpload(
  action: ReturnType<typeof uploadWireDocumentActions.request>
) {
  try {
    // const { File, onUploadSuccess, onUploadFail } =
    //   action.payload as IWireUploadDocRequest
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      uploadWireDocument(options, action?.payload)
    )
    if (response?.storagedocid || response?.storagedockey) {
      const payload: IWireSaveDocRequest = {
        TranId: action.payload.TranId,
        AccountNo: action.payload.metadata.pmtFromAccount,
        FileId: response.storagedocid,
        storageDocKey: response.storagedockey,
        DocDesc: action.payload.metadata.pmtDocDesc,
        executingCustodian: action.payload.executingCustodian
      }
      yield* call(() =>
        uploadWireDocumentMetadata(
          options,
          action?.payload.metadata,
          response.storagedocid,
          response.storagedockey
        )
      )
      const saveResponse = yield* call(() => saveWireDocument(options, payload))
      if (saveResponse?.statusCode === '0') {
        yield put(uploadWireDocumentActions.success(payload))
        // onUploadSuccess(File)
      } else {
        yield put(
          uploadWireDocumentActions.failure(new Error(saveResponse?.message))
        )
        // onUploadFail(File)
      }
    } else {
      yield put(
        uploadWireDocumentActions.failure(
          new Error(response?.rspStatus?.message)
        )
      )
      // onUploadFail(File)
    }
  } catch (e: any) {
    yield put(uploadWireDocumentActions.failure(e))
  }
}

function* handleClientSearch(
  action: ReturnType<typeof clientSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      search(
        'client',
        {
          query: action.payload,
          fullQuery: true,
          searchFields: ['LegalEntityName', 'srcClientNumber'],
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'LegalEntityName',
            'LegalEntityID',
            'ClientKPI/AumTotal',
            'loginDetails',
            'Account',
            'srcClientNumber',
            'id'
          ],
          count: true,
          top: 20
        },
        options
      )
    )
    if (response && response?.value) {
      yield put(clientSearchActions.success(response))
    } else {
      yield put(
        clientSearchActions.failure(new Error('unable to fetch client list'))
      )
    }
  } catch (e: any) {
    yield put(clientSearchActions.failure(e))
  }
}
export function accountSearch(
  account: string,
  options: IApiOptions,
  searchFields?: string[],
  accountList?: string[]
) {
  const filters: OdataPropertyFilterGroup[] = [
    {
      or: [
        {
          operator: OdataFilterOperatorEnum.searchin,
          path: 'id',
          type: 'string',
          value: accountList
        }
      ]
    }
  ]
  return search(
    'account',
    {
      query: account,
      fullQuery: true,
      searchFields: searchFields || [
        'CustodyAccount',
        'LegalEntityName',
        'AdvisorAddedNickName',
        'Shortname',
        'gfoCustodyAccount'
      ],
      select: [
        'ClientAdvisorID',
        'ClientAdvisor',
        'ClientAdvisorTeam',
        'CustodyAccount',
        'LegalEntityName',
        'LegalEntityID',
        'AccountKPIs/AccountTotal',
        'AdvisorAddedNickName',
        'Shortname',
        'CustodianType',
        'CustodianName',
        'registrationtype',
        'registrationDesc',
        'retirementAccount',
        'gfoCustodyAccount',
        'id',
        'householdId'
      ],
      count: true,
      top: 20,
      filters: accountList ? filters : undefined
    },
    options
  )
}

function* handleAccountSearch(
  action: ReturnType<typeof accountSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const response = yield* call(() =>
      accountSearch(action.payload ?? '', options)
    )
    if (response && response?.value) {
      yield put(accountSearchActions.success(response))
    } else {
      yield put(
        accountSearchActions.failure(new Error('unable to fetch account list'))
      )
    }
  } catch (e: any) {
    yield put(accountSearchActions.failure(e))
  }
}

function* handleAccountListSearch(
  action: ReturnType<typeof accountSearchActions.searchList>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const response = yield* call(() =>
      accountSearch('', options, undefined, action.payload)
    )
    if (response?.value) {
      yield put(accountSearchActions.success(response))
    } else {
      yield put(
        accountSearchActions.failure(new Error('unable to fetch account list'))
      )
    }
  } catch (e: any) {
    yield put(accountSearchActions.failure(e))
  }
}

function* handleFetchCountriesList(
  action: ReturnType<typeof fetchCountriesListActions.request>
) {
  const defautList = [
    {
      Code: 'US',
      Desc: 'United States',
      DomForeignCode: 'D'
    }
  ]
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      mmValidateService(action.payload, options)
    )

    const firstPartyPayeeDetails: ICheckPayeeDetails = {
      addrLine1: response?.AddrNameLine1,
      addrLine2: response?.AddrNameLine2,
      addrLine3: response?.AddrAttName,
      addrLine4: response?.AddrLine1,
      addrLine5: response?.AddrLine2,
      addrLine6_city: response?.City,
      addrLine7_stateProvince: response?.State,
      addrLine8_zip1: response?.Zip1,
      addrLine8_zip2: response?.Zip2
    }
    if (response?.countryList?.length) {
      const USItem = response?.countryList?.find((x) => x.Code === 'US')
      const index = response?.countryList?.findIndex((x) => x.Code === 'US')
      if (index && USItem) {
        // move US to the beginning of the list
        response?.countryList?.splice(index, 1)
        response?.countryList?.unshift(USItem)
      }
      yield put(
        fetchCountriesListActions.success({
          countries: response?.countryList,
          states: response?.stateList || [],
          firstPartyPayeeDetails,
          countryStateListResponse: response
        })
      )
    } else {
      yield put(
        fetchCountriesListActions.success({
          countries: response?.ischk ? [] : defautList,
          states: response?.stateList || [],
          firstPartyPayeeDetails,
          countryStateListResponse: response
        })
      )
    }
  } catch (e: any) {
    yield put(
      fetchCountriesListActions.success({
        countries: action?.payload?.ischk ? [] : defautList,
        states: [],
        countryStateListResponse: {}
      })
    )
  }
}
function* handleFetchInsufFundSolList() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchInsufFundSolutionListService(options)
    )
    if (response?.rspStatus?.statusCode === '0') {
      yield put(
        fetchInsufficientFundSolListActions.success(
          response?.solutionList || []
        )
      )
    } else {
      yield put(
        fetchInsufficientFundSolListActions.failure(
          new Error(response?.rspStatus?.message)
        )
      )
    }
  } catch (e: any) {
    yield put(fetchInsufficientFundSolListActions.failure(e))
  }
}

function* handleFetchHolidayList(
  action: ReturnType<typeof fetchHolidaysActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchholidayList(action.payload, options)
    )
    if (response?.rspStatus?.statusCode === '0') {
      yield put(fetchHolidaysActions.success(response?.holidayList || []))
    } else {
      yield put(
        fetchHolidaysActions.failure(new Error(response?.rspStatus?.message))
      )
    }
  } catch (e: any) {
    yield put(fetchHolidaysActions.failure(e))
  }
}

function* handleFetchCurrenciesCodeList() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => fetchCurrencyCodeListService(options))
    if (response?.rspStatus?.statusCode === '0') {
      yield put(fetchCurrencyCodeListActions.success(response?.rspn || []))
    } else {
      yield put(
        fetchCurrencyCodeListActions.failure(
          new Error(response?.rspStatus?.message)
        )
      )
    }
  } catch (e: any) {
    yield put(fetchCurrencyCodeListActions.failure(e))
  }
}

function* handleFetchPartyPhoneList(
  action: ReturnType<typeof fetchPartyPhoneListActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: PartyPhoneResponse = yield call(() =>
      fetchPartyPhoneNumbersService(action.payload, options)
    )
    yield put(
      fetchPartyPhoneListActions.success([...(data?.phoneNo || []), 'Other'])
    )
  } catch (e: any) {
    yield put(fetchPartyPhoneListActions.failure(e))
  }
}

function* handleFetchAuthUserList(
  action: ReturnType<typeof fetchAuthUserListActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IAccountProperties[] = yield call(() =>
      getAccountProperties(action.payload, options)
    )
    const account = data?.find((x) => x.accountNumber === action.payload) || {}
    if (account) {
      yield put(
        fetchAuthUserListActions.success({
          authUsers: account?.stkHolderDetails?.stkHldrs || [],
          accountPhNo: account?.accountDetails ?? {}
        })
      )
    } else {
      yield put(
        fetchAuthUserListActions.failure(new Error('Error fetching users.'))
      )
    }
  } catch (e: any) {
    yield put(fetchAuthUserListActions.failure(e))
  }
}

function* handleFetchMMDeviceInfo(
  action: ReturnType<typeof processMMDeviceInfoActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IMMDeviceInfoResponse = yield* call(() =>
      fetchMMDeviceInfo(action.payload, options)
    )
    yield put(processMMDeviceInfoActions.success(data))
  } catch (e: any) {
    yield put(processMMDeviceInfoActions.failure(e))
  }
}

function* handleFetchMethodOfInstructionsList() {
  try {
    //const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: string[] = yield call(() => fetchMethodofInstructionsService())
    yield put(fetchMethodOfInstructionListActions.success(data))
  } catch (e: any) {
    yield put(fetchMethodOfInstructionListActions.failure(e))
  }
}

function* handleCreatePayment(
  action: ReturnType<typeof createPaymentActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const createPaymentResponse = yield* call(() =>
      createPaymentService(action.payload, options)
    )
    if (createPaymentResponse?.response_status === 0) {
      yield put(createPaymentActions.success(createPaymentResponse))
      if (
        action.payload?.mmInstID !== createPaymentResponse?.entityId &&
        createPaymentResponse?.entityId &&
        action.payload?.mmInstID
      ) {
        yield put(
          fetchPaymentByIDActions.request({
            ID: createPaymentResponse?.entityId,
            reset: true
          })
        )
      }
    } else {
      yield put(
        createPaymentActions.failure(
          new Error(
            'An error occurred. Please check your data or contact support if the transaction seems correct.'
          )
        )
      )
    }
  } catch (e: any) {
    console.log(e)
    yield put(
      createPaymentActions.failure(
        new Error(
          'An error occurred. Please check your data or contact support if the transaction seems correct.'
        )
      )
    )
  }
}

function* handleFetchStandingInstructionList(
  action: ReturnType<typeof fetchPaymentStandingInstructionsActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IStandingInstructionsResponse = yield call(() =>
      getStandingInstructionsList(
        options,
        action.payload.account,
        action.payload.By,
        action.payload.IncludingBalance,
        action.payload.IncludingRejectedSI
      )
    )

    if (data?.value?.[0]?.Status?.Code === null) {
      yield put(
        fetchPaymentStandingInstructionsActions.success(
          data?.value?.[0]?.StandingInstructions || []
        )
      )
    } else {
      yield put(
        fetchPaymentStandingInstructionsActions.failure(
          new Error('Error fetching standing instructions.')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPaymentStandingInstructionsActions.failure(e))
  }
}

function* handleFetchPastPayments(
  action: ReturnType<typeof fetchPastPaymentsActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IPastPaymentsResponse = yield call(() =>
      getPastPayments(action.payload, options)
    )

    if (data?.rspStatus?.statusCode === '0') {
      yield put(fetchPastPaymentsActions.success(data))
    } else {
      yield put(
        fetchPastPaymentsActions.failure(
          new Error('Error fetching past payments.')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPastPaymentsActions.failure(e))
  }
}

function* handleReprocessTransaction(
  action: ReturnType<typeof reprocessTransactionActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IReprocessResponse = yield call(() =>
      reprocessTransaction(action.payload, options)
    )

    if (data?.statusCode === '0') {
      yield put(reprocessTransactionActions.success())
    } else {
      yield put(
        reprocessTransactionActions.failure(
          new Error('Error Processing Transaction.')
        )
      )
    }
  } catch (e: any) {
    yield put(reprocessTransactionActions.failure(e))
  }
}
function* handleDeclineTransaction(
  action: ReturnType<typeof declineTransactionActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IGenericResponse = yield call(() =>
      declineTransaction(action.payload, options)
    )

    if (data?.statusCode === '0') {
      yield put(declineTransactionActions.success())
    } else {
      yield put(
        declineTransactionActions.failure(
          new Error('Error Processing Transaction.')
        )
      )
    }
  } catch (e: any) {
    yield put(declineTransactionActions.failure(e))
  }
}
function* handleCloseTransaction(
  action: ReturnType<typeof closeTransactionActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const data: IGenericResponse = yield call(() =>
      closeTransaction(action.payload, options)
    )

    if (data?.statusCode === '0') {
      yield put(closeTransactionActions.success())
    } else {
      yield put(
        closeTransactionActions.failure(
          new Error('Error Processing Transaction.')
        )
      )
    }
  } catch (e: any) {
    yield put(closeTransactionActions.failure(e))
  }
}

function* handledeleteTransaction(
  action: ReturnType<typeof deleteTransactionActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const data: IGenericResponse = yield call(() =>
      deleteTransaction(action.payload, options)
    )

    if (data?.statusCode === '0') {
      yield put(deleteTransactionActions.success())
    } else {
      yield put(
        deleteTransactionActions.failure(
          new Error('Error Deleting Transaction.')
        )
      )
    }
  } catch (e: any) {
    yield put(deleteTransactionActions.failure(e))
  }
}

function* handleFetchPaymentAttension(
  action: ReturnType<typeof fetchPaymentAttensionActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IAttensionResponse = yield call(() =>
      evaluateTransactionRiskSerivce(action.payload, options)
    )

    if (data?.rspStatus?.statusCode === '0') {
      yield put(fetchPaymentAttensionActions.success(data))
      const riskAckData: IWireRiskAckRequest = {
        isInterimUpd: 'Y',
        RiskEvalRequestId: data?.riskEvalRequestId,
        CallerRefId: data?.callerRefId
      }
      riskAckData.RiskRuleRespUpdates = data.riskItems?.map((x) => {
        return {
          RiskRuleId: x.riskRuleId,
          IsConfirmed: 'N'
        }
      }) as IRiskRuleAck[]
      yield put(RiskAcknowledgementActions.setRiskAck(riskAckData))
    } else {
      yield put(
        fetchPaymentAttensionActions.failure(
          new Error('Non zero returned code received from api')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPaymentAttensionActions.failure(e))
  }
}

function* handleRiskAcknowledgement(
  action: ReturnType<typeof RiskAcknowledgementActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const request = cloneDeep(action.payload.riskAckRequest)
    if (action?.payload?.ruleId) {
      // send only the rule that is updated
      const updatedRulesList = request.RiskRuleRespUpdates?.filter(
        (x) => x.RiskRuleId === action.payload.ruleId
      )
      request.RiskRuleRespUpdates = updatedRulesList
    }
    const data: IWireRiskAckResponse = yield call(() =>
      submitRiskAcknowledgementService(request, options)
    )
    if (data?.rspStatus?.statusCode === '0') {
      yield put(RiskAcknowledgementActions.success(data))
      yield put(
        RiskAcknowledgementActions.setRiskAck(action.payload.riskAckRequest)
      )
    } else {
      yield put(
        RiskAcknowledgementActions.failure(
          new Error('Risk Acknowledgement failed')
        )
      )
    }
  } catch (e: any) {
    yield put(RiskAcknowledgementActions.failure(e))
  }
}

function* handleUpdateFedRefNoAction(
  action: ReturnType<typeof updateFedRefNoActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IUpdateFedRefResponse = yield call(() =>
      updateFedRefNo(action.payload, options)
    )
    if (data?.statusCode === '0') {
      yield put(updateFedRefNoActions.success('success'))
    } else {
      yield put(
        updateFedRefNoActions.failure(
          new Error('Unable to update Fed Reference Number')
        )
      )
    }
  } catch (e: any) {
    yield put(updateFedRefNoActions.failure(e))
  }
}
function* handleMMInstructionWire(
  action: ReturnType<typeof mmProcessActions.request>
) {
  try {
    const paymentType = action.payload.paymentType
    yield put(setFormDataActions.setWireFormData(action.payload.wireData))

    yield put(
      mmWireSaveAsDraftActions.request({
        paymentVerificationData: action.payload?.verificationData,
        wireData: action.payload.wireData,
        isWireInitiation: true,
        ismarginpaperworkrequired: undefined,
        paymentType: paymentType
      })
    )
    const { success } = yield* race({
      success: take(mmWireSaveAsDraftActions.success),
      failure: take(mmWireSaveAsDraftActions.failure)
    })

    if (success) {
      if (paymentType === TransactionType.EFT) {
        yield put(push('/transfers/eft/review'))
      } else {
        yield put(push('/transfers/wire/review'))
      }
    } else {
      yield put(
        mmProcessActions.failure(new Error('Failed to save wire as draft'))
      )
    }
  } catch (e: any) {
    yield put(mmProcessActions.failure(e))
  }
}

function* handleMMValidateWire(
  action: ReturnType<typeof mmValidatePaymentActions.request>
) {
  try {
    const {
      iswire,
      iseft,
      isjnl,
      ischk,
      AddrLine1,
      AddrLine2,
      City,
      State,
      Zip1,
      Zip2,
      addrFormat
    } = action.payload.validateRequest
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const data: IMMValidateResponse = yield call(() =>
      mmValidateService(action.payload?.validateRequest, options)
    )
    if (data) {
      yield put(mmValidatePaymentActions.success(data))
    } else {
      yield put(
        mmValidatePaymentActions.failure(
          new Error('Error fetching MMvalidate info')
        )
      )
    }
    if (
      data?.status?.Code === '0' &&
      ischk &&
      (addrFormat === 'D' || addrFormat === 'M') &&
      !action?.payload?.wireData?.payeeDetails?.overrideAddr &&
      !action?.payload?.wireData?.wireAmount?.useExistingSI &&
      action?.payload?.wireData?.wireAmount?.recipientType ===
        RecipientType.ThirdParty
    ) {
      const {
        AddrLine1: updatedAddrLine1,
        AddrLine2: updatedAddrLine2,
        City: updatedCity,
        State: updatedState,
        Zip1: updatedZip1,
        Zip2: updatedZip2
      } = data
      if (
        AddrLine1?.toLowerCase()?.trim() !==
          updatedAddrLine1?.toLowerCase()?.trim() ||
        AddrLine2?.toLowerCase()?.trim() !==
          updatedAddrLine2?.toLowerCase()?.trim() ||
        City?.toLowerCase()?.trim() !== updatedCity?.toLowerCase()?.trim() ||
        State?.toLowerCase()?.trim() !== updatedState?.toLowerCase()?.trim() ||
        Zip1?.toLowerCase()?.trim() !== updatedZip1?.toLowerCase()?.trim() ||
        (Zip2 &&
          Zip2?.toLowerCase()?.trim() !== updatedZip2?.toLowerCase()?.trim())
      ) {
        yield put(
          mmValidatePaymentActions.addressUpdate({
            hasAddressUpdates: true
          })
        )
        return
      }
    }
    if (data?.status?.Code === '0') {
      const updatedWireData: IWireInitiationForm = {
        ...action.payload.wireData,
        taxWithholdings: {
          ...action.payload.wireData.taxWithholdings,
          totalamtincludetaxwithhold: data?.totalamtincludetaxwithhold,
          fedwithholdamt: data?.fedwithholdamt,
          statewithholdamt: data?.statewithholdamt
        },
        payeeDetails: {
          ...action.payload.wireData.payeeDetails,
          addrLine8_zip2: data?.Zip2
        },
        IsOfacOnly: data?.IsOfacOnly
      }
      yield putResolve(setFormDataActions.setWireFormData(updatedWireData))
      yield put(
        mmWireSaveAsDraftActions.request({
          paymentVerificationData: action?.payload?.skipVerification
            ? {}
            : action.payload?.verificationData,
          wireData: updatedRequest(updatedWireData, data, iseft ?? false),
          isWireInitiation: true,
          ismarginpaperworkrequired: data?.ismarginpaperworkrequired,
          paymentType: action.payload?.paymentType
        })
      )
      const { success } = yield* race({
        success: take(mmWireSaveAsDraftActions.success),
        failure: take(mmWireSaveAsDraftActions.failure)
      })

      if (success) {
        // yield put(setFormDataActions.setWireFormData(action.payload.wireData))
        if (iswire) {
          yield put(push('/transfers/wire/review'))
        } else if (iseft) {
          yield put(push('/transfers/eft/review'))
        } else if (isjnl) {
          if (action.payload.skipVerification) {
            yield put(push('/transfers/journal/assessment'))
          } else {
            yield put(push('/transfers/journal/review'))
          }
        } else if (ischk) {
          yield put(push('/transfers/check/review'))
        }
      } else {
        yield put(
          mmWireSaveAsDraftActions.failure(
            new Error('Failed to save the transaction as draft')
          )
        )
      }
    }
  } catch (e: any) {
    yield put(mmValidatePaymentActions.failure(e))
  }
}

function* handleMMValidateAccount(
  action: ReturnType<typeof mmValidateAccountActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IMMValidateResponse | undefined = yield* call(() =>
      mmValidateService(action.payload, options)
    )
    if (data) {
      yield put(mmValidateAccountActions.success(data))
    } else {
      yield put(
        mmValidateAccountActions.failure(
          new Error('Error fetching MMvalidate info')
        )
      )
    }
  } catch (e: any) {
    yield put(mmValidateAccountActions.failure(e))
  }
}

function* handleSubmitPaymentReview(
  action: ReturnType<typeof SubmitPaymentReviewActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IPaymentReviewResponse = yield* call(() =>
      submitPaymentReview(action.payload, options)
    )
    if (data) {
      yield put(SubmitPaymentReviewActions.success(data))
    } else {
      yield put(
        SubmitPaymentReviewActions.failure(
          new Error('Error SubmittingPayment Review')
        )
      )
    }
  } catch (e: any) {
    yield put(SubmitPaymentReviewActions.failure(e))
  }
}

function* handleSubmitOPSAssignment(
  action: ReturnType<typeof SubmitOPSAssignmentActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IGenericResponse = yield* call(() =>
      SubmitOPSAssigment(action.payload, options)
    )
    if (data) {
      yield put(SubmitOPSAssignmentActions.success())
    } else {
      yield put(
        SubmitOPSAssignmentActions.failure(
          new Error('Error Submitting OPS Assignment')
        )
      )
    }
  } catch (e: any) {
    yield put(SubmitOPSAssignmentActions.failure(e))
  }
}

function* handleFetchPaymentApproveDetails(
  action: ReturnType<typeof FetchPaymentApproveDetailsActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IPaymentApproveDetailsResponse = yield* call(() =>
      getTranApproveDetail(action.payload, options)
    )
    if (data) {
      yield put(FetchPaymentApproveDetailsActions.success(data))
    } else {
      yield put(
        FetchPaymentApproveDetailsActions.failure(
          new Error('Error Fetching Approve Details')
        )
      )
    }
  } catch (e: any) {
    yield put(FetchPaymentApproveDetailsActions.failure(e))
  }
}

function* handleMMValidatePreapproval(
  action: ReturnType<typeof mmValidatePaymentActions.preapproval_request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IMMValidateResponse | undefined = yield* call(() =>
      mmValidateService(action.payload, options)
    )

    if (data) {
      yield put(mmValidatePaymentActions.success(data))
    } else {
      yield put(
        mmValidatePaymentActions.failure(
          new Error('Error fetching MMvalidate info')
        )
      )
    }
  } catch (e: any) {
    yield put(mmValidatePaymentActions.failure(e))
  }
}

function* handleMMWireSaveAsDraft(
  action: ReturnType<typeof mmWireSaveAsDraftActions.request>
) {
  try {
    const wirerequest = createWireRequest({
      wireInfo: action.payload.wireData,
      verificationInfo: action.payload.paymentVerificationData,
      ismarginpaperworkrequired: action.payload.ismarginpaperworkrequired,
      paymentType: action?.payload?.paymentType
    })

    yield put(createPaymentActions.request(wirerequest))
    const { success, failure } = yield* race({
      success: take(createPaymentActions.success),
      failure: take(createPaymentActions.failure)
    })

    if (failure || !success) {
      yield put(
        mmWireSaveAsDraftActions.failure(
          failure?.payload || new Error('Unable to save payment in draft')
        )
      )
      return
    }

    if (action.payload.paymentVerificationData) {
      yield put(
        setFormDataActions.setDisbursmentApprovalViewData(
          action.payload.paymentVerificationData
        )
      )
    }
    yield put(mmWireSaveAsDraftActions.success())
    if (!action.payload.isWireInitiation) {
      if (action.payload.paymentType === TransactionType.Journal) {
        yield put(push('/transfers/journal/assessment'))
      } else if (action.payload.paymentType === TransactionType.EFT) {
        yield put(push('/transfers/eft/assessment'))
      } else if (action.payload?.paymentType === TransactionType.Wire) {
        yield put(push('/transfers/wire/assessment'))
      } else if (action.payload?.paymentType === TransactionType.Check) {
        yield put(push('/transfers/check/assessment'))
      }
    }
  } catch (e: any) {
    yield put(mmWireSaveAsDraftActions.failure(e))
  }
}

function* handleMMWireSaveAndRefetchDocs(
  action: ReturnType<typeof mmWireSaveAsDraftActions.saveAndRefetchDocs>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const wirerequest = createWireRequest({
      wireInfo: updatedRequest(
        action.payload.wireData,
        action.payload.mmValidateResponse,
        action.payload.wireData.paymentType === TransactionType.EFT
      ),
      verificationInfo: action.payload.paymentVerificationData,
      paymentType: action.payload.paymentType
    })
    const createPaymentResponse = yield* call(() =>
      createPaymentService(wirerequest, options)
    )

    if (createPaymentResponse) {
      if (action.payload.wireData?.wirePaymentId) {
        yield put(
          fetchWireDocTypeListActions.request({
            tranType: action?.payload?.paymentType || 'WD',
            tranId: action.payload.wireData?.wirePaymentId || ''
          })
        )
      }
    }
  } catch (e: any) {
    yield put(fetchWireDocTypeListActions.failure(e))
  }
}

function* handleFetchPaymentRiskResult(
  action: ReturnType<typeof fetchPaymentRiskEvalResultActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IAttensionResponse = yield call(() =>
      getRiskEvalResultSerivce(
        action.payload.callerRefid,
        action.payload.role || '',
        options
      )
    )
    if (data?.rspStatus?.statusCode === '0') {
      yield put(fetchPaymentRiskEvalResultActions.success(data))

      yield put(fetchPaymentAttensionActions.success(data))
      const riskAckData: IWireRiskAckRequest = {
        isInterimUpd: 'Y',
        RiskEvalRequestId: data?.riskEvalRequestId,
        CallerRefId: data?.callerRefId
      }
      riskAckData.RiskRuleRespUpdates = data.riskItems?.map((x) => {
        return {
          RiskRuleId: x.riskRuleId,
          IsConfirmed: x.isConfirmed || 'N'
        }
      }) as IRiskRuleAck[]
      yield put(RiskAcknowledgementActions.setRiskAck(riskAckData))
    } else {
      yield put(
        fetchPaymentRiskEvalResultActions.failure(
          new Error('Non zero returned code received from api')
        )
      )
      yield put(
        fetchPaymentAttensionActions.failure(
          new Error('Non zero returned code received from api')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPaymentAttensionActions.failure(e))
    yield put(fetchPaymentRiskEvalResultActions.failure(e))
  }
}

function* handleFetchAccountBalance(
  action: ReturnType<typeof fetchAccountBalanceActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IAccountBalanceResponse = yield call(() =>
      fetchAccountBalance(options, action.payload)
    )
    if (data.status.Code === '0') {
      const accountBalance = data.accountbalances?.find(
        (x) => x.key === action.payload
      )
      yield put(fetchAccountBalanceActions.success(accountBalance || {}))
    } else {
      yield put(
        fetchAccountBalanceActions.failure(
          new Error('unable to fetch account balance')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchAccountBalanceActions.failure(e))
  }
}
function* handleFetchAnticipatedBalance(
  action: ReturnType<typeof fetchAnticipatedBalanceActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IAnticipatedBalance = yield call(() =>
      fetchAnticipatedAccountBalance(options, action.payload)
    )
    if (data) {
      yield put(fetchAnticipatedBalanceActions.success(data))
    } else {
      yield put(
        fetchAnticipatedBalanceActions.failure(
          new Error('unable to fetch anticipated balance')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchAnticipatedBalanceActions.failure(e))
  }
}

function* handleFetchWireType(
  action: ReturnType<typeof fetchWireTypeActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IgetWireTypeResponse = yield call(() =>
      getWireType(action?.payload, options)
    )
    if (data?.rspStatus?.statusCode === '0') {
      yield put(fetchWireTypeActions.success(data || {}))
    } else {
      yield put(
        fetchWireTypeActions.failure(new Error('unable to fetch wire type'))
      )
    }
  } catch (e: any) {
    yield put(fetchWireTypeActions.failure(e))
  }
}

function* handleFetchForeignAccountBalance(
  action: ReturnType<typeof fetchForeignAccountBalanceActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IForeignCurrencyPosition = yield call(() =>
      fetchForeignCurrencyBalance(
        action?.payload?.account || '',
        action?.payload?.currency || '',
        options
      )
    )
    if (data) {
      yield put(fetchForeignAccountBalanceActions.success(data || {}))
    } else {
      yield put(
        fetchForeignAccountBalanceActions.failure(
          new Error('unable to fetch account balance')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchForeignAccountBalanceActions.failure(e))
  }
}
function* handlePastStandingInstructions(
  action: ReturnType<typeof fetchPastStandingDataActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IPastStandingInstructionResponse = yield call(() =>
      getPastStandingInstructions(
        options,
        action.payload.account,
        action.payload.By
      )
    )
    if (data?.value?.[0]?.standingInstructions) {
      yield put(
        fetchPastStandingDataActions.success(
          data?.value?.[0]?.standingInstructions || []
        )
      )
    } else {
      yield put(
        fetchPastStandingDataActions.failure(
          new Error('Error fetching past standing instructions.')
        )
      )
    }
    //
  } catch (e: any) {
    yield put(fetchPastStandingDataActions.failure(e))
  }
}
function* handleFetchSTById(
  action: ReturnType<typeof fetchStandingInstByIdActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IMMSummarySIById = yield call(() =>
      getStandingInstructionById(
        options,
        action.payload.Id,
        action.payload.tranType
      )
    )
    if (data) {
      yield put(fetchStandingInstByIdActions.success(data))
    } else {
      yield put(
        fetchStandingInstByIdActions.failure(
          new Error('Error fetching standing instructions.')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchStandingInstByIdActions.failure(e))
  }
}
function* handleAccountOwners(
  action: ReturnType<typeof fetchAccountHolderActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IAccountHoldersResponse = yield call(() =>
      getAccountHolders(action.payload, options)
    )
    if (data) {
      yield put(
        fetchAccountHolderActions.success({
          isIndividual: data.isIndividual,
          owners: data.owners && data.isIndividual === 'N' ? data?.owners : [],
          rspStatus: data?.rspStatus
        })
      )
    } else {
      yield put(
        fetchAccountHolderActions.failure({
          error: new Error(undefined),
          isIndividual: undefined,
          owners: undefined
        })
      )
    }
    //
  } catch (e: any) {
    yield put(fetchAccountHolderActions.failure(e))
  }
}
function* handleStandingInstructionLimit() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IStandingInstructionLimitResponse = yield call(() =>
      getMMLimitsService(options)
    )
    if (data) {
      yield put(getMMLimitActions.success(data?.mmLimits))
    } else {
      yield put(getMMLimitActions.failure(new Error()))
    }
    //
  } catch (e: any) {
    yield put(getMMLimitActions.failure(e))
  }
}
function* handleFetchJournalConfig() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => fetchMMConfig(options))
    // yield put(getMMConfigActions.failure(new Error('unable to fetch config')))
    if (response) {
      yield put(getMMConfigActions.success(response))
    } else {
      yield put(getMMConfigActions.failure(new Error('unable to fetch config')))
    }
  } catch (e: any) {
    yield put(getMMConfigActions.failure(e))
  }
}
function* handleFetchPeriodicPaymentHistory(
  action: ReturnType<typeof fetchPeriodicHistoryActions.requestPaymentHistory>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IPeriodicPaymentHistoryResponse = yield* call(() =>
      getPeriodicPaymentHistory(action.payload, options)
    )
    if (data) {
      yield put(
        fetchPeriodicHistoryActions.successPaymentHistory(
          data.recurringPaymentList ?? []
        )
      )
    } else {
      yield put(
        fetchPeriodicHistoryActions.paymentfailure(
          new Error('Error Fetching Approve Details')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPeriodicHistoryActions.paymentfailure(e))
  }
}
function* handleFetchPeriodicPlanHistory(
  action: ReturnType<typeof fetchPeriodicHistoryActions.requestPlanHistory>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IPeriodicPlanHistoryResponse = yield* call(() =>
      getPeriodicPlanHistory(action.payload, options)
    )
    if (data) {
      yield put(
        fetchPeriodicHistoryActions.successPlanHistory(
          data.recurringPlanList ?? []
        )
      )
    } else {
      yield put(
        fetchPeriodicHistoryActions.planfailure(
          new Error('Error Fetching Approve Details')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPeriodicHistoryActions.planfailure(e))
  }
}

export const sagas = [
  () => takeLatest(FetchAbaDetailActions.request, handleFetchAbaDetails),
  () =>
    takeLatest(
      FetchSwiftCodeDetailActions.request,
      handleFetchSwiftCodeDetails
    ),
  () => takeLatest(FetchWirePaymentActions.request, handleFetchWirePayments),
  () => takeLatest(fetchCountriesListActions.request, handleFetchCountriesList),
  () => takeLatest(clientSearchActions.request, handleClientSearch),
  () => takeLatest(accountSearchActions.request, handleAccountSearch),
  () => takeLatest(accountSearchActions.searchList, handleAccountListSearch),
  () =>
    takeLatest(fetchAccountBalanceActions.request, handleFetchAccountBalance),
  () =>
    takeLatest(
      fetchAnticipatedBalanceActions.request,
      handleFetchAnticipatedBalance
    ),
  () =>
    takeLatest(
      fetchForeignAccountBalanceActions.request,
      handleFetchForeignAccountBalance
    ),
  () => takeLatest(fetchWireTypeActions.request, handleFetchWireType),
  () =>
    takeLatest(fetchWireDocTypeListActions.request, handleFetchWireDocTypes),
  () => takeLatest(uploadWireDocumentActions.request, handleWireUpload),
  () => takeLatest(deleteWireDocumentActions.request, handleDeleteDoc),
  () => takeLatest(viewWireDocumentActions.view, handleViewDoc),
  () =>
    takeLatest(
      fetchInsufficientFundSolListActions.request,
      handleFetchInsufFundSolList
    ),
  () => takeEvery(fetchHolidaysActions.request, handleFetchHolidayList),
  () =>
    takeLatest(
      fetchCurrencyCodeListActions.request,
      handleFetchCurrenciesCodeList
    ),
  () =>
    takeLatest(fetchPartyPhoneListActions.request, handleFetchPartyPhoneList),
  () => takeLatest(fetchAuthUserListActions.request, handleFetchAuthUserList),
  () => takeLatest(processMMDeviceInfoActions.request, handleFetchMMDeviceInfo),
  () =>
    takeLatest(
      fetchMethodOfInstructionListActions.request,
      handleFetchMethodOfInstructionsList
    ),
  () => takeLatest(createPaymentActions.request, handleCreatePayment),
  () =>
    takeLatest(reprocessTransactionActions.request, handleReprocessTransaction),
  () => takeLatest(declineTransactionActions.request, handleDeclineTransaction),
  () => takeLatest(closeTransactionActions.request, handleCloseTransaction),
  () =>
    takeLatest(
      fetchPaymentAttensionActions.request,
      handleFetchPaymentAttension
    ),
  () =>
    takeLatest(RiskAcknowledgementActions.request, handleRiskAcknowledgement),
  () => takeLatest(updateFedRefNoActions.request, handleUpdateFedRefNoAction),
  () =>
    takeLatest(
      fetchPaymentStandingInstructionsActions.request,
      handleFetchStandingInstructionList
    ),
  () => takeLatest(fetchPastPaymentsActions.request, handleFetchPastPayments),
  () => takeLatest(mmValidatePaymentActions.request, handleMMValidateWire),
  () => takeLatest(mmWireSaveAsDraftActions.request, handleMMWireSaveAsDraft),
  () =>
    takeLatest(
      mmWireSaveAsDraftActions.saveAndRefetchDocs,
      handleMMWireSaveAndRefetchDocs
    ),
  () =>
    takeLatest(
      fetchPaymentRiskEvalResultActions.request,
      handleFetchPaymentRiskResult
    ),
  () => takeLatest(mmValidateAccountActions.request, handleMMValidateAccount),
  () =>
    takeLatest(
      mmValidatePaymentActions.preapproval_request,
      handleMMValidatePreapproval
    ),
  () =>
    takeLatest(SubmitPaymentReviewActions.request, handleSubmitPaymentReview),
  () =>
    takeLatest(SubmitOPSAssignmentActions.request, handleSubmitOPSAssignment),
  () =>
    takeLatest(
      FetchPaymentApproveDetailsActions.request,
      handleFetchPaymentApproveDetails
    ),
  () => takeLatest(deleteTransactionActions.request, handledeleteTransaction),
  () => takeLatest(mmProcessActions.request, handleMMInstructionWire),
  () =>
    takeLatest(
      fetchPastStandingDataActions.request,
      handlePastStandingInstructions
    ),
  () => takeLatest(fetchStandingInstByIdActions.request, handleFetchSTById),
  () => takeLatest(fetchAccountHolderActions.request, handleAccountOwners),
  () => takeLatest(getMMLimitActions.request, handleStandingInstructionLimit),
  () => takeLatest(getMMConfigActions.request, handleFetchJournalConfig),
  () =>
    takeLatest(
      fetchPeriodicHistoryActions.requestPaymentHistory,
      handleFetchPeriodicPaymentHistory
    ),
  () =>
    takeLatest(
      fetchPeriodicHistoryActions.requestPlanHistory,
      handleFetchPeriodicPlanHistory
    )
]
