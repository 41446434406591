import { IDropdownOption } from '@fluentui/react'

export const PartyTypeOptions: IDropdownOption[] = [
  {
    key: 1,
    text: '3rd Party'
  }
]

export const VendotTypeOptions: IDropdownOption[] = [
  {
    key: '1',
    text: 'Business'
  },
  {
    key: '2',
    text: 'Individual'
  }
]

export const textFieldRegEx = /^([A-Za-z0-9?.,\-`:\s])*$/
export const vendorNameFieldRegEx = /^([A-Za-z0-9?.,\-`:&\s])*$/
